<template lang="">
  <v-container fluid>
    <v-layout row wrap align-center>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-layout row>
            <v-card-text class="title" style="padding-top: 30px"
              >পরীক্ষক কর্তৃক প্রদত্ত নম্বর ({{ examinerData }})</v-card-text
            >
            <v-divider class="my-2" vertical></v-divider>
            <v-spacer />
            <v-flex
              xs12
              sm6
              md8
              style="padding-left: 10px; padding-right: 10px; padding-top: 5px"
            >
              <v-select
                :items="exDataSet"
                label="পরীক্ষক নির্বাচন করুন"
                item-value="teacher_info.userpin"
                item-text="teacher_info.fullname"
                v-model="examinerData"
                required
                @change="getExMarks()"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card>
        <!-- {{getMarkCountListByStd}} -->
        <v-card>
          <v-layout row>
            <v-flex xs12 sm12 md12 v-if="examinerData">
              <table>
                <thead>
                  <tr>
                    <th rowspan="2">উত্তরপত্রের ক্রমিক নম্বর</th>
                    <th rowspan="2">পরীক্ষকের নম্বর</th>
                    <th
                      colspan="6"
                      style="
                        padding-top: 10px;
                        padding-bottom: 10px;
                        font-size: 16px;
                      "
                    >
                      প্রশ্ন সমূহ
                    </th>
                  </tr>
                  <tr>
                    <th style="padding-top: 10px">
                      উত্তরপত্রে কোন অপরীক্ষিত প্রশ্ন আছে কিনা?
                    </th>
                    <th>
                      উত্তরপত্রে প্রদত্ত সমস্ত নম্বর কভার পৃষ্ঠায় নির্ধারিত
                      স্থানে লিপিবদ্ধ আছে কিনা?
                    </th>
                    <th style="padding-top: 10px">
                      প্রাপ্ত নম্বরের সঠিক যোগফল সঠিক কিনা?
                    </th>
                    <th style="padding-top: 10px">
                      প্রাপ্ত নম্বরটি ওএমআর ফর্মের নির্ধারিত স্থানে রয়েছে যথাযথ
                      রেকর্ডিং এবং সেই অনুযায়ী সঠিক বৃত্তগুলি পূরণ করা হয়েছে?
                    </th>
                    <th style="padding-top: 10px">
                      কোনো প্রশ্ন অতিমূল্যায়িত/অমূল্যায়ন করা হয়েছে কিনা?
                    </th>
                    <th style="padding-top: 10px">
                      সংশ্লিষ্ট পরীক্ষকের কোড নম্বর উত্তরপত্রের ক্রমিক নম্বর/
                      বিষয়/ কোর্স /পত্রের কোড সঠিকভাবে লিখে বৃত্ত ভরাট এবং
                      যথাস্থানে স্বাক্ষর করেছে কিনা?
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="mark in scrutMarks" :key="mark">
                    <td style="text-align: center">{{ mark.script_sl }}</td>
                    <td>{{ mark.e_mark }}</td>
                    <td style="width: 200px">
                      <v-checkbox
                        v-model="mark.is_not_examine"
                        style="margin-left: 80px"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="mark.is_correct_mark_on_omr"
                        style="margin-left: 54px"
                      ></v-checkbox>
                    </td>
                    <td style="width: 200px">
                      <v-checkbox
                        v-model="mark.is_correct_sum_mark"
                        style="margin-left: 80px"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="mark.is_fillup_circle"
                        style="margin-left: 80px"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="mark.is_examine_mark"
                        style="margin-left: 54px"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="mark.is_signed_correctly"
                        style="margin-left: 88px"
                      ></v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-flex xs12 sm12 md12 style="text-align: center; padding: 40px">
                <v-btn color="primary" @click="saveScrutinyData()"
                  >Submit</v-btn
                >
              </v-flex>
            </v-flex>
            <v-flex
              xs12
              sm12
              md12
              v-else
              style="text-align: center; padding: 40px"
            >
              <p style="font-size: 28px; color: red; margin-top: 10px">
                অনুগ্রহ করে প্রথমে পরীক্ষক নির্বাচন করুন!
              </p>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";

export default {
  mixins: [commonGet, commonExamYear, common_login_getters],
  data() {
    return {
      is_examine_mark: false,
      correct_marks_on_omr: false,
      is_correct_sum_mark: false,
      is_fillup_circle: false,
      is_not_examine: false,
      is_signed_correctly: false,
      examinerData: "",
      exDataSet: [],
      fullNamesArray: [],
      scrutMarks: [],
    };
  },
  created() {
    this.searchParam.exam_initiate_id = this.getExamInitiateId;
    this.searchParam.user_id = this.getAuthUser.id;
    this.getScrutListData();
  },
  computed: {
    getDataOfscrutinizerList() {
      return this.$store.getters.dataOfscrutinizerList;
    },
    getMarkCountListByStd() {
      return this.$store.getters.getMarkCountListByStd;
    },
  },
  watch: {
    getMarkCountListByStd(val) {
      this.scrutMarks = val.map((resp) => {
        return {
          ...resp,
          is_not_examine: !!Number(resp.is_not_examine),
          is_correct_mark_on_omr: !!Number(resp.is_correct_mark_on_omr),
          is_correct_sum_mark: !!Number(resp.is_correct_sum_mark),
          is_fillup_circle: !!Number(resp.is_fillup_circle),
          is_examine_mark: !!Number(resp.is_examine_mark),
          is_signed_correctly: !!Number(resp.is_signed_correctly),
          scrutinizer_id: this.getDataOfscrutinizerList[0].id,
          user_id: this.getDataOfscrutinizerList[0].user_id,
        };
      });
    },
  },
  methods: {
    getExMarks() {
      let peram = {};
      peram.exam_initiate_id = this.getExamInitiateId;
      peram.examiner_code = this.examinerData;
      peram.exam_code = this.getLoggedInExam.code;
      peram.year = this.getLoggedInYear.year;
      this.loading = true;
      this.$store.dispatch("fetchMarkCountByStd", peram).then((data) => {
        console.log("Tanvir! called", data);
      });
    },
    saveScrutinyData() {
      let scrutExDataSet = this.scrutMarks.map(
        ({
          id,
          script_sl,
          paper_code,
          tims_id,
          exam_initiate_id,
          is_correct_sum_mark,
          is_correct_mark_on_omr,
          is_fillup_circle,
          is_examine_mark,
          is_not_examine,
          is_signed_correctly,
          scrutinizer_id,
          user_id
        }) => {
          return {
            id,
            script_sl,
            paper_code,
            tims_id,
            exam_initiate_id,
            is_correct_sum_mark,
            is_correct_mark_on_omr,
            is_fillup_circle,
            is_examine_mark,
            is_not_examine,
            is_signed_correctly,
            scrutinizer_id,
            user_id
          };
        }
      );
      console.log("Tanvir, This is scrut data", scrutExDataSet);
      this.$store.dispatch("postScrutinizerMarksData", scrutExDataSet);
    },

    getScrutListData() {
      this.$store
        .dispatch("fetchScrutinizerData", this.searchParam)
        .then((resp) => {
          console.log("Tanvir! this is scruck ex data", resp);
          if (resp.length > 0) {
            this.exDataSet = resp[0].examiners;

            console.log("exDataSet:", this.exDataSet);
            
          }
        });
    },
  },
};
</script>
<style>
table {
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff;
}

td {
  border: 1px solid #9b9b9b;
  padding: 6px;
  color: #000000;
  text-align: center;
  background-color: #fdfdfd;
}

thead th {
  background-color: #3f51b5;
}
</style>
